<template>
  <el-card id="dayReport">
    <header>
      <h1>日报表</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>日期</span>
            <el-date-picker
              v-model="form.day"
              type="date"
              placeholder="选择日期"
              size="mini"
            >
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span>公司ID</span>
            <el-input
              v-model="form.id"
              placeholder="请输入公司ID"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap" v-if="roleName == '企业'">
            <span>部门</span>
            <el-select v-model="departId" placeholder="请选择部门" size="mini">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.departName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        show-summary
      >
        <el-table-column
          prop="day"
          label="报表日期"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="userId" label="userId" align="center"></el-table-column> -->
        <el-table-column
          prop="name"
          label="公司名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="seconds"
          label="计费秒数(累加)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="minute"
          label="分钟数(累加)"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="总成本"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sumCount"
          label="接通总次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="count"
          label="接通成功次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usercount"
          label="接通总用户"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="count_sumCount"
          label="接通率"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="avg"
          label="avg平均通话时长"
          align="center"
        ></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 500]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
  </el-card>
</template>

<script>
import { tableDay } from "@/api/table";
import { sysDepartList } from "@/api/sys_depart";
export default {
  data() {
    return {
      form: {
        day: null,
        appid: "",
        name: null,
        minute: "",
        seconds: "",
        money: "",
        sumCount: "",
        count: "",
        usercount: "",
        avg: "",
        id: "",
      },
      options: [],
      departId: null,
      roleName: null,
      id: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  watch: {
    day() {
      if (this.from.day == "") {
        this.from.day = null;
      }
    },
    name() {
      if (this.from.name == "") {
        this.from.name = null;
      }
    },
  },
  created() {
    this.id = localStorage.getItem("id");
    this.roleName = localStorage.getItem("roleName");
    this.getList();
    this.getDepart();
  },
  methods: {
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    changeStyle(row, column, rowIndex, columnIndex) {
      console.log(row);
      console.log(column);
      console.log(rowIndex);
      console.log(columnIndex);
      if (columnIndex == 0) {
        return "background-color:red";
      }
    },
    getDepart() {
      let params = {
        topId: this.id,
      };
      let res = sysDepartList(params);
      res.then((res) => {
        this.options = res.data.data;
      });
    },
    handleSearch() {
      this.page = 1;
      this.form.day = this.getYMDHMS("YYYY-mm-dd", this.form.day);
      let params = {
        page: this.page,
        size: this.size,
        day: this.form.day,
        name: this.form.name,
        departId: this.departId,
        id: this.form.id,
      };
      let res = tableDay(params);
      res.then((res) => {
        if (res.data.success == false) {
          this.$router.push("/403");
        } else {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }
      });
    },
    //刷新按钮
    reset() {
      this.form.day = null;
      this.form.name = null;
      this.departId = null;
      this.form.id = null;
      this.getList();
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        day: this.form.day,
        name: this.form.name,
        departId: this.departId,
        id: this.form.id,
      };
      let res = tableDay(params);
      res.then((res) => {
        if (res.data.success == false) {
          this.$router.push("/403");
        } else {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#dayReport {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>